import "../iframe.html";

// Inputs
const urlInput = document.querySelector("#url");
const pauseInput = document.querySelector("#pause");
const speedInput = document.querySelector("#speed");
const c2hInput = document.querySelector("#c2h");
// Buttons
const previewButton = document.querySelector("#preview-btn");
const removeElementsButton = document.querySelector("#rm-cls-btn");
const addButton = document.querySelector("#add-btn");
const generateButton = document.querySelector("#generate-btn");
const openButton = document.querySelector("#open-btn");
const copyButton = document.querySelector("#copy-btn");
const IDXButton = document.querySelector("#idx-btn");
// IFrames
const iframe = document.querySelector("#iframe");
const classesListContainer = document.querySelector(".hide-list");

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:1234"
    : "https://page-scroller.aurafutures.cloud";

let classesArr = [];
let count = 0;
let link = "";

const onOpenClick = () => {
  const { origin, pathname } = new URL(urlInput.value);
  const iframeSrc = `${baseUrl}${pathname}`;

  fetch(`https://page-scroller.aurafutures.cloud/iframe?domain=${origin}`).then(
    res => console.log("res:", res)
  );

  if (urlInput.value) {
    iframe.setAttribute("src", iframeSrc);
  }
};

const createElement = className => {
  const classItem = document.createElement("div");
  const deleteButton = document.createElement("div");

  ++count;
  classItem.setAttribute("data-id", `${count}`);
  classItem.classList.add("class-item");
  classItem.innerText = className;

  deleteButton.classList.add("delete-btn");
  deleteButton.innerText = "X";

  deleteButton.addEventListener("click", e => {
    const el = e.target.parentElement;
    const elId = e.target.parentElement.dataset.id;

    classesArr = classesArr.filter(item => item.id !== +elId);
    classesListContainer.removeChild(el);

    onOpenClick();
  });

  classItem.appendChild(deleteButton);
  classesListContainer.appendChild(classItem);
};

const removeElementsFromPage = () => {
  classesArr.forEach(item => {
    const element = iframe.contentDocument.querySelector(`${item.value}`);
    if (!!element) {
      element.style.display = "none";
    }
  });
};

const generateLink = () => {
  if (urlInput.value) {
    const classesToHide = classesArr.map(({ value }) => value).join(",");
    const newUrl = new URL(urlInput.value);

    const classesToHideQuery = classesToHide.length
      ? `&elements-to-hide=${encodeURIComponent(classesToHide)}`
      : "";

    link = `${baseUrl}/iframe?domain=${newUrl.origin}&url=${newUrl.pathname}&speed=${speedInput.value}&pause=${pauseInput.value}${classesToHideQuery}`;
  }
};

const copyLink = () => {
  generateLink();
  navigator.clipboard.writeText(link);
};

const openURL = () => {
  generateLink();

  const newWindow = window.open(link, "_blank", "noopener,noreferrer");

  if (newWindow) {
    newWindow.opener = null;
  }
};

const addElementToHideList = () => {
  createElement(c2hInput.value);
  classesArr.push({ id: count, value: c2hInput.value });
  c2hInput.value = "";

  removeElementsFromPage();
};

const defineInitialElementsList = () => {
  const elements = ["footer", ".aulv5v-0", ".fVaIzk"];

  for (const element of elements) {
    createElement(element);
    classesArr.push({ id: count, value: element });
  }
};

const redirectToIDX = () => {
  const newWindow = window.open(
    "https://staging-idx.aurafutures.cloud/#/applications",
    "_self",
    "noopener,noreferrer"
  );

  if (newWindow) {
    newWindow.opener = null;
  }
};

if (previewButton) {
  previewButton.onclick = onOpenClick;
}

if (removeElementsButton) {
  removeElementsButton.onclick = removeElementsFromPage;
}

if (addButton) {
  addButton.addEventListener("click", () => {
    if (!!c2hInput.value) {
      addElementToHideList();
    }
  });
}

if (openButton) {
  openButton.addEventListener("click", openURL);
}

if (copyButton) {
  copyButton.addEventListener("click", copyLink);
}

if (c2hInput) {
  c2hInput.addEventListener("keyup", event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      addElementToHideList();
    }
  });
}

if (generateButton) {
  generateButton.addEventListener("click", generateLink);
}

if (IDXButton) {
  IDXButton.addEventListener("click", redirectToIDX);
}

if (iframe) {
  iframe.onload = () => {
    removeElementsFromPage();
  };
}

(() => defineInitialElementsList())();
